export const environment = {
  production: true,
  baseUrl : 'https://diunsa.epik.com.co:6645/',
  baseNode: 'https://diunsa.epik.com.co:6690',
  clientid: '641de0d28d9b37001b1bdfa0',
  //flowid: '644046791b27e9001ce29a33',//desarrollo
  flowid:'643ef506dc533c001babb8f3', //produccion
  nombreProyecto: 'Diunsa Epik',
  tiempoOtp: 3,
  version:"1.1.26"
};
