// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl : 'https://desarrollo.epik.com.co:6688', // dev
  // baseUrl : 'https://diunsa.epik.com.co:6645/', // prod
  //baseUrl : 'http://localhost:52103/',
  
  // baseNode: 'https://diunsa.epik.com.co:6690', //prod
  baseNode: 'https://desarrollo.epik.com.co:6601', // desa
  //baseNode: 'http://localhost:8081',
  clientid: '641de0d28d9b37001b1bdfa0',
  flowid: '644046791b27e9001ce29a33',//desarrollo
  //flowid:'643ef506dc533c001babb8f3', //produccion
  nombreProyecto: 'Diunsa Epik (Pruebas)',
  tiempoOtp:3,
  version:"1.1.26"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
